
export default {
    name: "AppHeader",
    data() {
        return {
            isMobile: false,
            isHidden: true,
            paused: false
        }
    },
    mounted () {
        this.onResize()
        window.addEventListener('resize', this.onResize, { passive: true })
        this.isHidden = this.isMobile
    },
    created(){
        this.interval = setInterval(() => this.paused = true, 2000);
        this.interval = setInterval(() => this.paused = false, 5000);
    },
    methods: {
        onResize () {
            this.isMobile = window.innerWidth < 600
        }
    },
    watch: {
      $route() {
        if (this.isMobile) {
            this.isHidden = true;       
        }
      },
    },
}
