
import axios from 'axios'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
    name: "ContactForm",
    computed: mapState(['leads']),
    data() {
        return {
            email: '',
            phone: "",
            message: '',
            sent: false
        }
    },
    methods: {
        ...mapActions(['getLeads']),
        sendMessage(e) {
            e.preventDefault()
            const {email, phone, message} = Object.fromEntries(new FormData(e.target))
            this.email = email
            this.phone = phone
            this.message = message
            // Simple POST request with a JSON body using fetch

            axios.post('/leads', { email: this.email, phone: this.phone, message: this.message })
            .then((response) => {
                if (response.status == '201') {
                    console.log('message sent')
                    this.sent = !this.sent
                    this.$refs.contactForm.reset();
                    setTimeout(() => this.sent = !this.sent, 3000)
                } else {
                    console.log("message not sent")
                }
                //console.log(response)
            }, (error) => {
                console.log(error);
            });
        }
    }

}
