import axios from 'axios'
export const state = () => ({
    leads: []
})

export const actions = {
    async getLeads() {
        const leads = await axios.get('https://webconsol.ro/leads')
        state.leads =  leads
        return leads.data

    }
}
  
export const mutations = {
    add(state, form) {
        state.leads.push({
        form,
        })
    },
    remove(state, { form }) {
        state.leads.splice(state.leads.indexOf(form), 1)
    }
}
// export const getters = {
//     getLeads(state) {
//         return state.leads
//     }
// }