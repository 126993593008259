import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _412a0d10 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _4b934c9c = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _bd50453a = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _3b47ddd7 = () => interopDefault(import('../pages/prices.vue' /* webpackChunkName: "pages/prices" */))
const _0ef82e6a = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _5ce09a1d = () => interopDefault(import('../pages/servicii.vue' /* webpackChunkName: "pages/servicii" */))
const _0adfa60a = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _60e1e7d5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _412a0d10,
    name: "about"
  }, {
    path: "/blog",
    component: _4b934c9c,
    name: "blog"
  }, {
    path: "/contact",
    component: _bd50453a,
    name: "contact"
  }, {
    path: "/prices",
    component: _3b47ddd7,
    name: "prices"
  }, {
    path: "/privacy",
    component: _0ef82e6a,
    name: "privacy"
  }, {
    path: "/servicii",
    component: _5ce09a1d,
    name: "servicii"
  }, {
    path: "/terms",
    component: _0adfa60a,
    name: "terms"
  }, {
    path: "/",
    component: _60e1e7d5,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
